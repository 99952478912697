const categoryPics = [
    'asian-beef-1.jpg',             'asian-salad-1.jpg',          'dessert-1.jpg',
    'asian-beef-noodles-1.jpg',      'asian-soup-1.jpg',           'drinks-1.jpg',
    'asian-chicken-broccoli-1.jpg',  'asian-specialities-1.jpg',
    'asian-chicken-broccoli-2.jpg',  'asian-starters-1.jpg',       'peking-duck-1.jpg',
    'asian-noodles-1.jpg',           'asian-thick-noodles-1.jpg',  'salsa-1.jpg',
    'asian-noodles-2.jpg',           'asian-vegetables-1.jpg',     'shrimps-1.jpg',
    'tomato-rice-1.jpg', 'asian-noodle-soup-1.jpg', 'children-potatoes-1.jpg', 'asian-fish-1.jpg',
    'asian-special-1.jpg', 'asian-special-2.jpg', 'dimsum-1.jpg','dimsum-2.jpg','dimsum-3.jpg','dimsum-4.jpg',
    'jiaozi-1.jpg', 'noodles-1.jpg', 'noodles-2.jpg', 'rice-1.jpg', 'shrimps-2.jpg', 'thai-curry-1.jpg',
    'udon-noodles-1.jpg','vegetables-1.jpg', 'burger-1.jpg', 'burger-2.jpg','burger-menu-1.jpg',
    'finger-food-1.jpg','finger-food-2.jpg','hot-wings-1.jpg','italian-salad-1.jpg','onion-rings-1.jpg','pizza-1.jpg','pizza-2.jpg','pizza-3.jpg','pizza-4.jpg','sandwich-1.jpg',
    'fish-1.jpg', 'auflauf-1.jpg', 'doener-1.jpg', 'doener-2.jpg', 'fish-2.jpg', 'schiffchen-2.jpg'
]

module.exports = categoryPics
