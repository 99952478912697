<template>
  <div>
    <div v-if="!loading">
      <v-row>
        <v-btn :disabled="!hasSavedChanges" class="mr-2" text
               @click="submitAllChanges">Speisekarte speichern
        </v-btn>
        <v-btn :disabled="!hasSavedChanges" class="mr-2" text
               @click="discardAllChanges">Änderungen verwerfen
        </v-btn>
      <v-spacer></v-spacer>
        <v-btn class="mr-2" text @click="handleNewCategoryClick()">Neue Kategorie</v-btn>
      </v-row>
      <v-row v-for="category in mutableFoodCategories" v-bind:key="category.id">
        <v-col cols="12">
          <v-card class="my-7">
            <v-card-title>
              {{ category.name + ' - ' + category.index }}
              <v-spacer></v-spacer>
              <v-btn class="mr-2" icon @click="handleNewItemClick(category)">
                <v-icon>{{ mdiPlus }}</v-icon>
              </v-btn>
              <v-btn :disabled="category.index==1" icon @click="handleCategoryUp(category)">
                <v-icon>{{ mdiArrowUp }}</v-icon>
              </v-btn>
              <v-btn icon @click="handleCategoryDown(category)">
                <v-icon>{{ mdiArrowDown }}</v-icon>
              </v-btn>
              <v-btn class="mr-2" icon @click="handleEditCategoryClick(category)">
                <v-icon>{{ mdiPencil }}</v-icon>
              </v-btn>
              <v-btn icon @click="deleteSingleCategory(category)">
                <v-icon>{{ mdiDelete }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle style="white-space: pre-wrap;">{{ category.description }}</v-card-subtitle>
            <v-row>
              <v-col cols="12">
                <v-img v-if="category.image" :src="getImgUrl(category.image)" height="210px"></v-img>
              </v-col>
            </v-row>
            <v-lazy min-height="80">
              <v-data-table
                  v-model="selectedFoodItems[category.id]"
                  :headers="headers"
                  :hide-default-footer="true"
                  :items="mutableFoodItems.filter(x=>x.category == category.id)"
                  class="elevation-1"
                  disable-pagination
                  disable-sort
                  item-key="id"
                  no-data-text="In dieser Kategorie sind keine Gerichte!"
                  show-select
              >
                <template v-slot:item.price="{ item }">
                  <span class="text-no-wrap">{{ item.price / 100 |currency }}</span>
                </template>
                <template v-slot:item.description="{ item }">
                  <span style="white-space: pre-wrap;">{{ item.description }}</span>
                </template>
                <template v-slot:item.name="{ item }">
                  <span :class="{'green--text': item.veg}" class="text-no-wrap">{{ item.name }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                      :disabled="item.index==1"
                      class="mr-2"
                      small
                      @click="handleItemUp(item)"
                  >
                    {{ mdiArrowUp }}
                  </v-icon>
                  <v-icon
                      class="mr-2"
                      small
                      @click="handleItemDown(item)"
                  >
                    {{ mdiArrowDown }}
                  </v-icon>
                  <v-icon
                      class="mr-2"
                      small
                      @click="handleEditItemClick(item)"
                  >
                    {{ mdiPencil }}
                  </v-icon>
                  <v-icon
                      small
                      @click="deleteSingleItem(item)"
                  >
                    {{ mdiDelete }}
                  </v-icon>
                </template>
                <template v-slot:item.extras="{ item }">
                  <div v-if="item.queries">
                    {{ item.queries.includes('extras') ? 'Ja' : 'Nein' }}
                  </div>
                  <div v-else>Nein</div>
                </template>
                <template v-slot:item.questions="{ item }">
                  <div v-if="item.queries">
                    {{ item.queries.includes('questions') ? 'Ja' : 'Nein' }}
                  </div>
                  <div v-else>Nein</div>
                </template>
              </v-data-table>
            </v-lazy>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
          v-if="itemDialog"
          v-model="itemDialog"
          :fullscreen="$vuetify.breakpoint.mdAndDown"
          max-width="800px">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="itemDialog = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <div v-if="!dialogLoading">
            <v-card-text>
              <v-text-field
                  ref="itemNameField"
                  v-model="editableItem.name"
                  label="Name"
              ></v-text-field>
              <v-textarea
                  v-model="editableItem.description"
                  dense
                  label="Beschreibung"
              ></v-textarea>
              <v-text-field
                  v-model="editableItem.number"
                  label="Nummer"
              ></v-text-field>
              <v-text-field
                  v-model.number="editableItem.price"
                  :hint="editableItem.price > 0 ? editableItem.price/100 + '€' : ''"
                  label="Preis in Cent"
                  persistent-hint
                  type="number"
              ></v-text-field>
              <v-checkbox v-model="editableItem.veg" label="Vegetarisch/Vegan"></v-checkbox>
              <v-select
                  v-model="editableItem.category"
                  :disabled="itemDialogAction=='add'"
                  :items="mutableFoodCategories"
                  item-text="name"
                  item-value="id"
                  label="Kategorie"
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="itemDialogAction=='add'"
                     :disabled="!(editableItem.name && editableItem.price && editableItem.category)" text
                     @click="handleItemSave(true)">Bestätigen
                und
                Neu
              </v-btn>
              <v-btn :disabled="!(editableItem.name && editableItem.price && editableItem.category)"
                     text @click="handleItemSave(false)">Bestätigen
              </v-btn>
            </v-card-actions>
          </div>
          <v-card-text v-else>
            <v-layout align-center class="pt-5 pb-8" justify-center>
              <v-progress-circular
                  color="primary"
                  indeterminate
                  size="80"
              ></v-progress-circular>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
          v-if="itemSelectedDialog"
          v-model="itemSelectedDialog"
          :fullscreen="$vuetify.breakpoint.mdAndDown"
          max-width="900px"
          persistent>
        <v-card>
          <v-card-title>
            <v-layout>
              <div v-if="selected.length > 1">{{ selected.length + ' ausgewählte Gerichte' }}</div>
            </v-layout>
            <v-spacer></v-spacer>
            <v-btn icon
                   @click="itemSelectedDialog = false; selectedItemsPriceEditable = false; selectedItemsDescriptionEditable = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            {{ selectedItemsNamesString }}
          </v-card-subtitle>
          <v-slide-y-transition>
            <v-banner v-show="selectedItemsPriceEditable || selectedItemsDescriptionEditable"
                      class="pt-1 mb-3"
                      color='deep-orange lighten-3'
                      single-line>
              <v-icon
                  slot="icon"
                  color="red"
                  size="36"
              >
                {{ mdiExclamation }}
              </v-icon>
              <h3>
                Die gemachten Änderungen gelten für alle ausgewählten Gerichte!<br>
                {{ selectedItemsPriceEditable ? 'Geben Sie den Preis in CENT ein!' : '' }}
              </h3>
            </v-banner>
          </v-slide-y-transition>
          <v-card-text>
            <v-row v-if="selected.length > 1">
              <v-col cols="12">
                <h3 class="black--text">Beschreibungen</h3>
              </v-col>
              <v-col v-if="!selectedItemsDescriptionEditable" cols="11">
                {{ selectedItemsDescriptionString }}
              </v-col>
              <v-col v-else class="pt-0" cols="10">
                <v-text-field v-model="selectedItemsDescriptionInput"></v-text-field>
              </v-col>
              <v-col v-if="!selectedItemsDescriptionEditable" cols="1">
                <v-btn icon
                       @click="selectedItemsDescriptionEditable = !selectedItemsDescriptionEditable">
                  <v-icon>{{ mdiPencil }}</v-icon>
                </v-btn>
              </v-col>
              <v-col v-else cols="2">
                <v-btn icon @click="handleChosenItemsDescriptionSave">
                  <v-icon>{{ mdiCheck }}</v-icon>
                </v-btn>
                <v-btn icon @click="selectedItemsDescriptionEditable = false">
                  <v-icon>{{ mdiClose }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="selected.length > 1">
              <v-col cols="12">
                <h3 class="black--text">Preise</h3>
              </v-col>
              <v-col v-if="!selectedItemsPriceEditable" cols="11">
                {{ selectedItemsPricesString }}
              </v-col>
              <v-col v-else class="pt-0" cols="10">
                <v-text-field v-model.number="selectedItemsPriceInput" type="number"></v-text-field>
              </v-col>
              <v-col v-show="!selectedItemsPriceEditable" cols="1">
                <v-btn icon @click="selectedItemsPriceEditable = !selectedItemsPriceEditable">
                  <v-icon>{{ mdiPencil }}</v-icon>
                </v-btn>
              </v-col>
              <v-col v-show="selectedItemsPriceEditable" cols="2">
                <v-btn :disabled="!selectedItemsPriceInput" icon @click="handleChosenItemsPriceSave">
                  <v-icon>{{ mdiCheck }}</v-icon>
                </v-btn>
                <v-btn icon @click="selectedItemsPriceEditable = false">
                  <v-icon>{{ mdiClose }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-card class="mb-3">
              <v-card-title>
                <h3>Fragen:</h3>
                <v-spacer></v-spacer>
                <v-btn class="mr-2" text @click="handleNewQuestionClick">Neue Frage</v-btn>
              </v-card-title>
              <v-card-text>
                <div v-for="question in selectedItemsQuestions" :key="question.id">
                  <v-row no-gutters>
                    <v-col cols="10">
                      <div :class="{'red--text' : question.mandatory}" class="text-h6 font-weight-bold">
                        {{ question.question }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip v-if="selected.length > 1" top>
                        <template v-slot:activator="{on}">
                          <v-icon v-on="on" class="mx-2">{{ mdiInformation }}</v-icon>
                        </template>
                        <span v-if="question.mandatory">Die Frage ist notwendig <br></span>
                        <span>Vorhanden in den folgenden Gerichten: <br></span>
                        <span>{{ itemStringFromIds(question.items) }}</span>
                      </v-tooltip>
                      <v-btn @click="handleEditQuestionClick(question)" icon>
                        <v-icon>{{ mdiPencil }}</v-icon>
                      </v-btn>
                      <v-btn icon @click="deleteSingleQuestion(question)">
                        <v-icon>{{ mdiDelete }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expansion-panels>
                    <v-expansion-panel
                        v-for="choice in question.choices.filter(x => x.extras && x.extras.length > 0)"
                        :key="choice.name + '-' + choice.price"
                        no-gutters>
                      <v-expansion-panel-header class="text-subtitle-1">
                        {{ choice.name + ' - ' + choice.price / 100 + '€' }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content v-if="choice.extras">
                        <v-btn class="mb-4" color="green"
                               @click="copiedExtras = JSON.parse(JSON.stringify(choice.extras))">
                          Kopieren
                        </v-btn>
                        <v-row no-gutters>
                          <v-col cols="6">Name:</v-col>
                          <v-col cols="3">Preis:</v-col>
                          <v-col cols="3">Kategorie:</v-col>
                        </v-row>
                        <v-row no-gutters v-for="extra in choice.extras"
                               :key="extra.name + extra.price + extra.category">
                          <v-col cols="6">{{ extra.name }}</v-col>
                          <v-col cols="3">{{ extra.price / 100 | currency }}</v-col>
                          <v-col cols="3">{{ extra.category }}</v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-row
                      v-for="choice in question.choices.filter(x => !x.hasOwnProperty('extras') || (x.extras.length == 0))"
                      :key="choice.name + '-' + choice.price" class="px-5">
                    <v-col cols="12">
                      <div class="text-subtitle-1">{{ choice.name + ' - ' + choice.price / 100 + '€' }}</div>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
            <v-card class="mb-3">
              <v-card-title>
                <h3>Extras:</h3>
                <v-spacer></v-spacer>
                <v-btn v-if="selectedItemsExtras && selectedItemsExtras.length > 0" color="green"
                       @click="copiedExtras = JSON.parse(JSON.stringify(selectedItemsExtras.map(x => {return{category: x.category, name: x.name, price: x.price}})))">
                  kopieren
                </v-btn>
                <v-btn v-if="copiedExtras.length > 0" text color="orange"
                       @click="handleExtraPaste">
                  einfügen
                </v-btn>
                <v-btn class="mr-2" text @click="handleNewExtraClick">Neues Extra</v-btn>
              </v-card-title>
              <v-card-text>
                <div v-for="extra in selectedItemsExtras" :key="extra.id" class="pb-1">
                  <v-row no-gutters>
                    <v-col cols="10">
                      <div class="title font-weight-medium black--text">{{
                          extra.name + ' - ' +
                          extra.price / 100 + '€'
                        }} - <span class="font-weight-light body-2"> {{ extra.category }}</span>
                      </div>

                    </v-col>
                    <v-col cols="2">
                      <v-tooltip v-if="selected.length > 1" top>
                        <template v-slot:activator="{on}">
                          <v-icon v-on="on" class="mx-2">{{ mdiInformation }}</v-icon>
                        </template>
                        <span>Vorhanden in den folgenden Gerichten: <br></span>
                        <span>{{ itemStringFromIds(extra.items) }}</span>
                      </v-tooltip>
                      <v-btn icon @click="handleEditExtraClick(extra)">
                        <v-icon>{{ mdiPencil }}</v-icon>
                      </v-btn>
                      <v-btn icon @click="deleteSingleExtra(extra)">
                        <v-icon>{{ mdiDelete }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title>
                <h3>Allergene und Zusatzstoffe</h3>
                <v-spacer></v-spacer>
                <v-btn class="mr-2" text @click="handleEditRequiredLabelsClick">Bearbeiten</v-btn>
              </v-card-title>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
          v-if="categoryDialog"
          v-model="categoryDialog"
          :fullscreen="$vuetify.breakpoint.mdAndDown"
          max-width="800px">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="categoryDialog = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-text-field
                ref="categoryNameField"
                v-model="editableCategory.name"
                label="Name"
            ></v-text-field>
            <v-textarea
                v-model="editableCategory.description"
                dense
                label="Beschreibung"
            ></v-textarea>
            <v-select v-model="editableCategory.image"
                      :items="extraCategoryPics.concat(categoryPics)"
                      label="Bild"
            >
              <template v-slot:item="{ item }">
                          <span>
                            <v-icon>{{ mdiArrowDown }}</v-icon>
                            {{ item }}
                          <v-img :src="getImgUrl(item)" height="210px" width="600px"></v-img>
                          </span>
              </template>
            </v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!editableCategory.name" text
                   @click="handleCategorySave">Bestätigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-if="extraInputDialog"
          v-model="extraInputDialog"
          :fullscreen="$vuetify.breakpoint.mdAndDown"
          max-width="800px">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="extraInputDialog = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <div v-if="!dialogLoading">
            <v-card-text>
              <v-expansion-panels v-if="selected.length > 1">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Ausgewählte Gerichte
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row no-gutters>
                      <v-col cols="6" v-for="item in selected" :key="item.id">
                        <v-checkbox v-model="itemCheckboxSelection" :label="item.name"
                                    :value="item.id"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-text-field
                  ref="itemNameField"
                  v-model="extraNameInput"
                  label="Name"
              ></v-text-field>
              <v-text-field
                  v-model.number="extraPriceInput"
                  :hint="extraPriceInput > 0 ? extraPriceInput/100 + '€' : ''"
                  label="Preis in Cent"
                  type="number"
              ></v-text-field>
              <v-text-field
                  v-model="extraCategoryInput"
                  label="Kategorie"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!extraNameInput || !extraCategoryInput ||extraPriceInput === undefined" text
                     @click="handleExtraSave">Bestätigen
              </v-btn>
            </v-card-actions>
          </div>
          <v-card-text v-else>
            <v-layout align-center class="pt-5 pb-8" justify-center>
              <v-progress-circular
                  color="primary"
                  indeterminate
                  size="80"
              ></v-progress-circular>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
          v-if="questionInputDialog"
          v-model="questionInputDialog"
          :fullscreen="$vuetify.breakpoint.mdAndDown"
          max-width="800px">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="questionInputDialog = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <div v-if="!dialogLoading">
            <v-card-text>
              <v-expansion-panels v-if="selected.length > 1">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Ausgewählte Gerichte
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row no-gutters>
                      <v-col cols="6" v-for="item in selected" :key="item.id">
                        <v-checkbox v-model="itemCheckboxSelection" :label="item.name"
                                    :value="item.id"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row>
                <v-col cols="9">
                  <v-text-field
                      ref="itemNameField"
                      v-model="questionNameInput"
                      label="Frage"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="questionMandatoryInput" label="Notwendig?"></v-checkbox>
                </v-col>

              </v-row>
              <v-card class="mb-3">
                <v-card-title>
                  <h3>Antworten:</h3>
                </v-card-title>
                <v-card-text>
                  <div v-for="(answer,i) in inputAnswers" :key="answer.name">
                    <v-row class="mb-3" no-gutters>
                      <v-col class="hoverBackground" cols="6" @click="changeAnswerName(i)">
                        <div class="title font-weight-medium black--text">
                          {{ answer.name }}
                        </div>
                      </v-col>
                      <v-col class="hoverBackground" cols="5" @click="changeAnswerPrice(i)">
                        <div class="title font-weight-medium black--text">
                          {{ answer.price / 100 |currency }}
                        </div>
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon @click="deleteAnswer(answer)">
                          <v-icon>{{ mdiDelete }}</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" v-if="answer.extras">
                        <v-row v-for="(extra,j) in answer.extras"
                               :key="extra.name + '-' + extra.price + '-' + extra.category"
                               no-gutters :class="j %2 === 0 ? 'greyBackground' : '' ">
                          <v-col cols="4" @click="changeNameOfAnswerExtra(i,j)" class="hoverBackground">
                            {{ extra.name }}
                          </v-col>
                          <v-col cols="3" @click="changeCategoryOfAnswerExtra(i,j)" class="hoverBackground">
                            {{ extra.category }}
                          </v-col>
                          <v-col cols="4" @click="changePriceOfAnswerExtra(i,j)" class="hoverBackground">
                            {{ extra.price / 100 |currency }}
                          </v-col>
                          <v-col cols="1">
                            <v-btn small icon @click="answer.extras.splice(j,1)">
                              <v-icon small>{{ mdiDelete }}</v-icon>
                            </v-btn>
                          </v-col>

                          <v-col cols="3">
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col class="pt-0 pb-0 mb-0" cols="9">
                      <v-text-field
                          ref="itemNameField"
                          v-model="answerNameInput"
                          label="Antwort"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="3">
                      <v-btn :disabled="!answerNameInput || answerPriceInput === undefined || answerPriceInput === ''"
                             @click="handleNewAnswerClick">
                        Hinzufügen
                        <v-icon>{{ mdiPlus }}</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="pt-0 pb-0 mt-0" cols="9">
                      <v-text-field
                          v-model.number="answerPriceInput"
                          :hint="answerPriceInput > 0 ? answerPriceInput/100 + '€' : ''"
                          class="pt-0"

                          label="Preis in Cent"
                          type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0 mt-0" cols="3">
                      <v-btn text @click="answerExtrasToggle = !answerExtrasToggle">
                        <v-icon class="mr-3">{{ answerExtrasToggle ? mdiChevronUp : mdiChevronDown }}</v-icon>
                        extras
                      </v-btn>
                    </v-col>
                  </v-row>
                  <span v-if="answerExtrasArray.length > 0" no-gutters>
                    <v-row>
                      <v-col cols="12">
                    <h4>EXTRAS:</h4>
                        </v-col>
                      </v-row>
                        <v-row no-gutters>
                          <v-col cols="5">Name:</v-col>
                          <v-col cols="3">Preis:</v-col>
                          <v-col cols="3">Kategorie:</v-col>
                      </v-row>
                    <v-row no-gutters v-for="(extra,i) in answerExtrasArray"
                           :key="extra.name + '-' + extra.price + '-' + extra.category">
                      <v-col cols="5">{{ extra.name }}</v-col>
                      <v-col cols="3">{{ extra.price / 100 | currency }}</v-col>
                      <v-col cols="3">{{ extra.category }}</v-col>
                      <v-col cols="1">
                        <v-btn icon @click="answerExtrasArray.splice(i,1)"><v-icon>{{ mdiDelete }}</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </span>
                  <v-row class="pa-3" v-if="answerExtrasToggle" style="background: #f7eee9">
                    <v-tooltip>
                      <template v-slot:activator="{on}">
                        <v-col cols="1">
                          <v-icon v-on="on"> {{ mdiInformation }}</v-icon>
                        </v-col>
                      </template>
                      <span>Sie können hier Extras hinzufügen, die spezifisch für die Antwort der Frage sind. <br>
                        Wenn Sie zB abhängig von der Frage nach der Größe einer Pizza die Extrawünsche unterschiedlich <br>
                        bepreisen wollen können Sie hier Extras hinzufügen die von der Größe abhängen.</span>
                    </v-tooltip>
                    <v-col cols="11">
                      <h2 class="d-inline">EXTRAMENÜ </h2>
                    </v-col>
                    <v-col cols="9">
                      <v-text-field
                          ref="itemNameField"
                          v-model="extraNameInput"
                          label="Name"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="3">
                      <v-btn :disabled="!extraNameInput || !extraCategoryInput ||extraPriceInput === undefined"
                             @click="handleNewExtraForAnswerClick">
                        Hinzufügen
                        <v-icon>{{ mdiPlus }}</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                          v-model.number="extraPriceInput"
                          :hint="extraPriceInput > 0 ? extraPriceInput/100 + '€' : ''"
                          label="Preis in Cent"
                          type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox v-model="answerKeepExtras"
                                  label="Extras für die nächste Antwort beibehalten"></v-checkbox>
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                          v-model="extraCategoryInput"
                          label="Kategorie"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-btn color="orange" text v-if="copiedExtras.length > 0"
                             @click="answerExtrasArray = JSON.parse(JSON.stringify(copiedExtras)); copiedExtras = []">
                        extras
                        einfügen
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="!(questionNameInput && inputAnswers.length > 1)" text
                     @click="handleQuestionSave">Bestätigen
              </v-btn>
            </v-card-actions>
          </div>
          <v-card-text v-else>
            <v-layout align-center class="pt-5 pb-8" justify-center>
              <v-progress-circular
                  color="primary"
                  indeterminate
                  size="80"
              ></v-progress-circular>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
          v-if="requiredLabelsinputDialog"
          v-model="requiredLabelsinputDialog"
          :fullscreen="$vuetify.breakpoint.mdAndDown"
          max-width="800px">
        <v-card>
          <v-card-title>
            Die momentanen Allergene und Zusazustoffe werden überschrieben.
            <v-spacer></v-spacer>
            <v-btn icon @click="requiredLabelsinputDialog = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <div v-if="!dialogLoading">
            <v-card-text>
              <v-expansion-panels v-if="selected.length > 1">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Ausgewählte Gerichte
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row no-gutters>
                      <v-col cols="6" v-for="item in selected" :key="item.id">
                        <v-checkbox v-model="itemCheckboxSelection" :label="item.name"
                                    :value="item.id"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-divider></v-divider>
              <v-divider></v-divider>
              <v-divider></v-divider>
              <v-divider></v-divider>
              <v-divider></v-divider>
              <v-divider class="pb-4"></v-divider>

              <v-row no-gutters>
                <v-col v-for="number in Object.keys(labels)" :key="number" cols="12">
                  <v-checkbox v-model="requiredLabelsInput" :label="labels[number]" :value="number" class="pa-0 ma-0"
                              dense></v-checkbox>
                </v-col>
              </v-row>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text
                     @click="handleRequiredLabelSave">Bestätigen
              </v-btn>
            </v-card-actions>
          </div>
          <v-card-text v-else>
            <v-layout align-center class="pt-5 pb-8" justify-center>
              <v-progress-circular
                  color="primary"
                  indeterminate
                  size="80"
              ></v-progress-circular>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>


    </div>
    <v-layout v-else align-center class="pt-5 pb-8" justify-center>
      <v-progress-circular
          color="primary"
          indeterminate
          size="80"
      ></v-progress-circular>
    </v-layout>
    <v-slide-y-reverse-transition>
      <v-btn v-if="selected.length > 0" bottom fab fixed right x-large
             @click="handleEditSelectedItemsClick">
        <v-icon>{{ mdiPencil }}</v-icon>
      </v-btn>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {store} from "../store";
import Vue from "vue";
import {uuidv4} from "../utils/uuid";
import {
  mdiPlus,
  mdiArrowUp,
  mdiArrowDown,
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiInformation,
  mdiDelete,
  mdiExclamation,
  mdiClose,
  mdiPencil
} from '@mdi/js'
const categoryPics = require("@/assets/categoryPics");
import {extraCategoryPics} from "@/restaurantConfig";


// In this bad boy you create a temporary new menu, the mutableFoodItems and the mutableFoodCategories.
// At the end, when saving mutableFoodItems and mutableFoodCategories will be compared to their original counterparts
// and all the new/changed items will be noted, and then put into the database. The deleted items will be deleted from the db, too.
export default {
  name: "MenuEditor",
  data() {
    return {
      //icons
      categoryPics: categoryPics.sort(),
      extraCategoryPics: extraCategoryPics.sort(),
      mdiPlus,
      mdiDelete,
      mdiArrowUp,
      mdiArrowDown,
      mdiClose,
      mdiInformation,
      mdiExclamation,
      mdiChevronDown,
      mdiChevronUp,
      mdiPencil,
      mdiCheck,
      loading: true,
      //The copy of the menu
      mutableFoodCategories: [],
      mutableFoodItems: [],
      //The items selected in the table
      selectedFoodItems: {},
      //The booleans that control the visibility of dialogs and their respective actions
      itemDialog: false,
      itemSelectedDialog: false,
      categoryDialog: false,
      questionInputDialog: false,
      extraInputDialog: false,
      requiredLabelsinputDialog: false,
      dialogLoading: false,
      categoryDialogAction: undefined,
      itemDialogAction: undefined,
      //The copies of objects that are being edited
      editableItem: {},
      editableCategory: {},
      //Inputs for editing/adding extras/questions/requiredLabels
      itemCheckboxSelection: [],
      extraNameInput: '',
      extraCategoryInput: '',
      extraPriceInput: undefined,
      inputAnswers: [],
      questionNameInput: '',
      questionMandatoryInput: false,
      answerNameInput: '',
      answerPriceInput: '',
      answerExtrasToggle: false,
      answerKeepExtras: false,
      answerExtrasArray: [],
      originalExtra: {},
      originalQuestion: {},
      requiredLabelsInput: [],
      //Booleans that control if you can edit the description/price during multi-item editing and
      //their respective input fields
      selectedItemsDescriptionInput: undefined,
      selectedItemsPriceInput: undefined,
      selectedItemsDescriptionEditable: false,
      selectedItemsPriceEditable: false,
      //tracks if any change-inducing actions were made. Doesn't work super well though.lol
      hasSavedChanges: false,
      //Aggregate of the extras/questions/descriptions... of the selected items.
      //This is done, so there are no duplicates shown.
      copiedExtras: [],
      selectedItemsExtras: [],
      selectedItemsQuestions: [],
      selectedItemsRequiredLabels: [],
      selectedItemsDescriptionString: "",
      selectedItemsPricesString: "",
      selectedItemsNamesString: "",
      //headers for the table. these should not be subject to change
      headers: [
        {
          text: 'Name',
          align: 'start',
          width: '29%',
          value: 'name'
        },
        {
          text: 'Beschreibung',
          width: '34%',
          value: 'description'
        },
        {
          text: 'Preis',
          width: '11%',
          value: 'price'
        },
        {
          text: 'Extras',
          width: '7%',
          value: 'extras',
        },
        {
          text: 'Fragen',
          width: '7%',
          value: 'questions',
        },
        {
          text: 'Aktionen',
          width: '14%',
          value: 'actions'
        }
      ],
      //List of possible required Labels
      labels: {
        1: "Glutenhaltiges Getreide sowie daraus hergestellte Erzeugnisse",
        2: "Krebstiere und daraus gewonnene Erzeugnisse",
        3: "Eier und daraus gewonnene Erzeugnisse",
        4: "Fisch und daraus gewonnene Erzeugnisse",
        5: "Erdnüsse und daraus gewonnene Erzeugnisse",
        6: "Soja und daraus gewonnene Erzeugnisse",
        7: "Milch und daraus gewonnene Erzeugnisse (einschließlich Laktose)",
        8: "Schalenfrüchte, sowie daraus hergestellte Erzeugnisse",
        9: "Sellerie und daraus gewonnene Erzeugnisse",
        10: "Senf und daraus gewonnene Erzeugnisse",
        11: "Sesamsamen und daraus gewonnene Erzeugnisse",
        12: "Schwefeldioxid und Sulfite in einer Konzentration von mehr als 10 mg/kg oder 10 mg/l",
        13: "Lupinen und daraus gewonnene Erzeugnisse",
        14: "Weichtiere, und daraus gewonnene Erzeugnisse",
        15: "mit Farbstoff",
        16: "mit Konservierungsstoff/en",
        17: "mit Nitritpökelsalz",
        18: "mit Nitrat",
        19: "mit Antioxidationsmittel",
        20: "mit Geschmacksverstärker",
        21: "geschwefelt",
        22: "geschwärzt",
        23: "gewachst",
        24: "mit Phosphat",
        25: "mit Süßungsmitteln",
        26: "enthält eine Phenylalaninquelle",
        27: "koffeinhaltig",
        28: "chininhaltig"
      }
    }
  },
  methods: {
    ...mapActions({
      setLastUpdated: 'menuStore/setLastUpdated',
      editFoodCategory: 'menuStore/editFoodCategory',
      addFoodCategory: 'menuStore/addFoodCategory',
      deleteFoodCategory: 'menuStore/deleteFoodCategory',
      editFoodItem: 'menuStore/editFoodItem',
      addFoodItem: 'menuStore/addFoodItem',
      deleteFoodItem: 'menuStore/deleteFoodItem',
      setExtras: 'menuStore/setExtras',
      setQuestions: 'menuStore/setQuestions',
      ensureQueries: 'menuStore/ensureQueries',
    }),
    getImgUrl(name, lazy) {
      if (lazy) {
        return require('../assets/categoryPics/lq/' + name)

      } else {
        return require('../assets/categoryPics/' + name)
      }
    },
    getMutableItems() {
      //This gets either the local changes or makes a copy of the menu to edit
      if ((localStorage.mutableFoodCategories || localStorage.mutableFoodItems) && confirm("Es wurden ungespeicherte Änderungen gefunden. Wiederherstellen?")) {
        this.mutableFoodCategories = localStorage.mutableFoodCategories ? JSON.parse(localStorage.mutableFoodCategories) : JSON.parse(JSON.stringify(this.foodCategories))
        this.mutableFoodItems = localStorage.mutableFoodItems ? JSON.parse(localStorage.mutableFoodItems) : JSON.parse(JSON.stringify(this.foodItems))
        this.hasSavedChanges = true
      } else {
        localStorage.removeItem('mutableFoodCategories')
        localStorage.removeItem('mutableFoodItems')
        this.mutableFoodCategories = JSON.parse(JSON.stringify(this.foodCategories))
        this.mutableFoodItems = JSON.parse(JSON.stringify(this.foodItems))
      }
    },
    //these are called when you click the plus do add a new object
    handleNewCategoryClick() {
      this.categoryDialog = true
      this.categoryDialogAction = 'add'
      this.editableCategory = {}
      Vue.nextTick().then(() => {
        this.$refs.categoryNameField.focus()
      })
    },
    handleNewItemClick(obj) {
      this.itemDialog = true
      this.itemDialogAction = 'add'
      this.editableItem = {category: obj.id}
      Vue.nextTick().then(() => {
        this.$refs.itemNameField.focus()
      })
    },
    handleNewExtraClick() {
      this.extraInputDialog = true
      this.originalExtra = {}
      this.itemCheckboxSelection = this.selected.map(x => x.id)
      this.extraNameInput = ''
      this.extraCategoryInput = 'extras'
      this.extraPriceInput = undefined
    },
    handleEditRequiredLabelsClick() {
      this.requiredLabelsinputDialog = true
      this.itemCheckboxSelection = this.selected.map(x => x.id)
      this.requiredLabelsInput = []
    },
    handleNewQuestionClick() {
      this.questionInputDialog = true
      this.originalQuestion = {}
      this.itemCheckboxSelection = this.selected.map(x => x.id)
      this.questionNameInput = ''
      this.questionMandatoryInput = false
      this.answerNameInput = ''
      this.answerExtrasArray = []
      this.extraNameInput = ''
      this.answerExtrasToggle = false
      this.answerKeepExtras = false
      this.extraPriceInput = ''
      this.extraCategoryInput = 'extras'
      this.answerPriceInput = ''
      this.inputAnswers = []
    },
    handleNewExtraForAnswerClick() {
      this.answerExtrasArray.push(JSON.parse(JSON.stringify({
            name: this.extraNameInput,
            price: this.extraPriceInput,
            category: this.extraCategoryInput
          }))
      )
      this.extraNameInput = ''
      this.extraPriceInput = ''
      this.extraCategoryInput = 'extras'
    },
    changeAnswerPrice(i) {
      let input = Number(window.prompt('Geben Sie den neuen Preis ein IN CENT!', this.inputAnswers[i].price.toString()))
      if (input != null && !Number.isNaN(input)) {
        Vue.set(this.inputAnswers[i], 'price', input)

      }
    },
    changeAnswerName(i) {
      let input = window.prompt('Geben Sie den neuen Namen ein!', this.inputAnswers[i].name)
      if (input != null) {
        Vue.set(this.inputAnswers[i], 'name', input)
      }
    },
    changePriceOfAnswerExtra(i, j) {
      let input = Number(window.prompt('Geben Sie den neuen Preis ein IN CENT!', this.inputAnswers[i].extras[j].price.toString()))
      if (input != null && !Number.isNaN(input)) {
        Vue.set(this.inputAnswers[i].extras[j], 'price', input)
      }
    },
    changeNameOfAnswerExtra(i, j) {
      let input = window.prompt('Geben Sie den neuen Namen ein.', this.inputAnswers[i].extras[j].name.toString())
      if (input != null) {
        Vue.set(this.inputAnswers[i].extras[j], 'name', input)
      }
    },
    changeCategoryOfAnswerExtra(i, j) {
      let input = window.prompt('Geben Sie die neue Kategorie ein', this.inputAnswers[i].extras[j].category.toString())
      if (input != null) {
        Vue.set(this.inputAnswers[i].extras[j], 'category', input)
      }
    },
    handleNewAnswerClick() {
      if (this.inputAnswers.some(x => x.name == this.answerNameInput)) {
        window.alert('Es gibt bereits eine Antwort mit dem selben Namen.')
        return
      }
      let obj = {
        name: this.answerNameInput,
        price: this.answerPriceInput,
      }
      if (this.answerExtrasArray.length > 0) {
        obj.extras = this.answerExtrasArray
      }
      this.inputAnswers.push(JSON.parse(JSON.stringify(obj)))
      this.extraNameInput = ''
      this.extraPriceInput = ''
      this.extraCategoryInput = 'extras'
      if (!this.answerKeepExtras) {
        this.answerExtrasArray = []
      }
      this.answerNameInput = ""
      this.answerPriceInput = ""
    },
    //these functions are called when you click on the edit pencil somewhere
    handleEditCategoryClick(obj) {
      this.categoryDialog = true
      this.categoryDialogAction = 'edit'
      this.editableCategory = JSON.parse(JSON.stringify({
        id: obj.id,
        name: obj.name,
        image: obj.image,
        description: obj.description
      }))
      Vue.nextTick().then(() => {
        this.$refs.categoryNameField.focus()
      })
    },
    handleEditItemClick(item) {
      this.editableItem = JSON.parse(JSON.stringify(item))
      this.dialogLoading = true
      this.itemDialog = true
      this.itemDialogAction = 'edit'
      this.ensureQueries(this.editableItem).then(result => {
        if (result) {
          result.forEach(queryResult => {
            if (queryResult) {
              this.editableItem[queryResult[1]] = JSON.parse(JSON.stringify(queryResult[0]))
              item[queryResult[1]] = JSON.parse(JSON.stringify(queryResult[0]))
            }
          })

        }
        this.dialogLoading = false
        Vue.nextTick().then(() => {
          this.$refs.itemNameField.focus()
        })
      })
    },
    handleEditQuestionClick(question) {
      console.log(question)
      this.questionInputDialog = true
      this.itemCheckboxSelection = JSON.parse(JSON.stringify(question.items))
      this.originalQuestion = JSON.parse(JSON.stringify(question))
      this.questionNameInput = JSON.parse(JSON.stringify(question.question))
      this.questionMandatoryInput = JSON.parse(JSON.stringify(question.mandatory || false))
      this.inputAnswers = JSON.parse(JSON.stringify(question.choices))
      this.originalQuestion.edit = true
    },
    handleEditExtraClick(extra) {
      this.extraInputDialog = true
      this.itemCheckboxSelection = JSON.parse(JSON.stringify(extra.items))
      this.originalExtra = JSON.parse(JSON.stringify(extra))
      this.extraNameInput = JSON.parse(JSON.stringify(extra.name))
      this.extraCategoryInput = JSON.parse(JSON.stringify(extra.category))
      this.extraPriceInput = JSON.parse(JSON.stringify(extra.price))
      this.originalExtra.edit = true
    },
    handleEditSelectedItemsClick() {
      // This fetches all queries before showing the page
      this.itemSelectedDialog = true
      this.dialogLoading = true
      let resolvedItemPromises = this.selected.map(item => {
        return this.ensureQueries(item).then(result => {
          if (result) {
            result.forEach(queryResult => {
              if (queryResult) {
                Vue.set(item, queryResult[1], JSON.parse(JSON.stringify(queryResult[0])))
              }
            })
          }
        })
      })
      Promise.all(resolvedItemPromises).then(() => {
        this.selectedItemsExtras = []
        this.selectedItemsQuestions = []
        this.selected.forEach(item => {
          if (item.extras) {
            item.extras.forEach(extra => {
              let existingExtra = this.selectedItemsExtras.find(x => x.name == extra.name && x.price == extra.price && x.category == extra.category)
              if (existingExtra) {
                existingExtra.items.push(item.id)
              } else {
                Vue.set(extra, 'items', [item.id])
                this.selectedItemsExtras.push(extra)
              }
            })
          }
          if (item.questions) {
            item.questions.forEach(question => {
              let matchingQuestionIndex = this.findQuestionInArray(question, this.selectedItemsQuestions)
              if (matchingQuestionIndex != -1) {
                this.selectedItemsQuestions[matchingQuestionIndex].items.push(item.id)
              } else {
                Vue.set(question, 'items', [item.id])
                this.selectedItemsQuestions.push(JSON.parse(JSON.stringify(question)))
              }
            })
          }
        })
        //filter(Boolean) removes all falsy items, in this case empty strings.
        this.selectedItemsDescriptionString = [...new Set(this.selected.map(item => item.description))].filter(Boolean).join(', ')
        this.selectedItemsPricesString = [...new Set(JSON.parse(JSON.stringify(this.selected)).sort((a, b) => a.price - b.price).map(item => (item.price / 100 + ' €')))].join(', ')
        this.selectedItemsNamesString = this.selected.map(item => item.name).join(', ')
        this.dialogLoading = false
      })
    },
    //These move up foods/categories
    handleCategoryUp(obj) {
      obj = JSON.parse(JSON.stringify(obj))
      let objIndex = this.mutableFoodCategories.findIndex(category => category.id == obj.id)
      let secondObjIndex = this.mutableFoodCategories.findIndex(category => category.index == obj.index - 1)
      if (secondObjIndex != -1) {
        Vue.set(this.mutableFoodCategories[objIndex], 'index', obj.index - 1)
        Vue.set(this.mutableFoodCategories[secondObjIndex], 'index', obj.index)
        this.mutableFoodCategories.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
      }
      localStorage.mutableFoodCategories = JSON.stringify(this.mutableFoodCategories);
      this.hasSavedChanges = true
    },
    handleCategoryDown(obj) {
      obj = JSON.parse(JSON.stringify(obj))
      let objIndex = this.mutableFoodCategories.findIndex(category => category.id == obj.id)
      let secondObjIndex = this.mutableFoodCategories.findIndex(category => category.index == obj.index + 1)
      if (secondObjIndex != -1) {
        Vue.set(this.mutableFoodCategories[objIndex], 'index', obj.index + 1)
        Vue.set(this.mutableFoodCategories[secondObjIndex], 'index', obj.index)
        this.mutableFoodCategories.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
      }
      localStorage.mutableFoodCategories = JSON.stringify(this.mutableFoodCategories);
      this.hasSavedChanges = true
    },
    handleItemUp(obj) {
      obj = JSON.parse(JSON.stringify(obj))
      let objIndex = this.mutableFoodItems.findIndex(item => item.id == obj.id)
      let secondObjIndex = this.mutableFoodItems.findIndex(item => item.category == obj.category && item.index == obj.index - 1)
      if (secondObjIndex != -1) {
        Vue.set(this.mutableFoodItems[objIndex], 'index', obj.index - 1)
        Vue.set(this.mutableFoodItems[secondObjIndex], 'index', obj.index)
        this.mutableFoodItems.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
      }
      localStorage.mutableFoodItems = JSON.stringify(this.mutableFoodItems);
      this.hasSavedChanges = true
    },
    handleItemDown(obj) {
      obj = JSON.parse(JSON.stringify(obj))
      let objIndex = this.mutableFoodItems.findIndex(item => item.id == obj.id)
      let secondObjIndex = this.mutableFoodItems.findIndex(item => item.category == obj.category && item.index == obj.index + 1)
      if (secondObjIndex != -1) {
        Vue.set(this.mutableFoodItems[objIndex], 'index', obj.index + 1)
        Vue.set(this.mutableFoodItems[secondObjIndex], 'index', obj.index)
        this.mutableFoodItems.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
      }
      localStorage.mutableFoodItems = JSON.stringify(this.mutableFoodItems);
      this.hasSavedChanges = true
    },
    //these handle clicking save buttons
    handleCategorySave() {
      if (this.categoryDialogAction == 'add') {
        let maxIndexObj = this.mutableFoodCategories.reduce((prev, current) => (prev.index > current.index) ? prev : current, {index: 0})
        this.editableCategory.index = maxIndexObj.index + 1
        this.editableCategory.id = uuidv4();
        this.mutableFoodCategories.push(JSON.parse(JSON.stringify(this.editableCategory)))
      } else if (this.categoryDialogAction == 'edit') {
        let index = this.mutableFoodCategories.findIndex(category => category.id == this.editableCategory.id)
        Object.keys(this.editableCategory).forEach(key => {
          Vue.set(this.mutableFoodCategories[index], key, this.editableCategory[key])
        })
      }
      localStorage.mutableFoodCategories = JSON.stringify(this.mutableFoodCategories);
      this.hasSavedChanges = true
      this.categoryDialog = false
    },
    handleItemSave(addAnother) {
      if (this.itemDialogAction == 'add') {
        if (this.mutableFoodItems.filter(x => x.category == this.editableItem.category)) {
          let maxIndexObj = JSON.parse(JSON.stringify(this.mutableFoodItems.filter(x => x.category == this.editableItem.category)
              .reduce((prev, current) => (prev.index > current.index) ? prev : current, {index: 0})))
          this.editableItem.index = maxIndexObj.index + 1
          this.editableItem.id = uuidv4();
        } else {
          this.editableItem.index = 1
        }
        this.mutableFoodItems.push(JSON.parse(JSON.stringify(this.editableItem)))
      } else if (this.itemDialogAction == 'edit') {
        let itemIndex = this.mutableFoodItems.findIndex(item => item.id == this.editableItem.id)
        let sort = false
        if (this.editableItem.category != this.mutableFoodItems[itemIndex].category) {
          let maxIndexObj = JSON.parse(JSON.stringify(this.mutableFoodItems.filter(x => x.category == this.editableItem.category)
              .reduce((prev, current) => (prev.index > current.index) ? prev : current, {index: 0})))
          this.editableItem.index = maxIndexObj.index + 1
          sort = true
        }
        this.mutableFoodItems.forEach(obj => {
          if (obj.index > this.mutableFoodItems[itemIndex].index && obj.category == this.mutableFoodItems[itemIndex].category) {
            obj.index--
          }
        })
        Object.keys(this.editableItem).forEach(key => {
          Vue.set(this.mutableFoodItems[itemIndex], key, this.editableItem[key])
        })
        if (sort) {
          this.mutableFoodItems.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
        }
      }
      localStorage.mutableFoodItems = JSON.stringify(this.mutableFoodItems);
      this.hasSavedChanges = true
      if (!addAnother) {
        this.itemDialog = false
      } else {
        this.editableItem = {category: this.editableItem.category}
        Vue.nextTick().then(() => {
          this.$refs.itemNameField.focus()
        })
      }

    },
    handleExtraSave() {
      if (this.originalExtra.edit) {
        if (this.itemCheckboxSelection.length > 0) {
          //This edits the name/price of the set of the extras of all selected items
          //The .items prop is set dynamically a bit further down
          let extraSetIndex = this.selectedItemsExtras.findIndex(x => x.name == this.originalExtra.name && x.price == this.originalExtra.price && x.category == this.originalExtra.category)
          this.selectedItemsExtras[extraSetIndex].name = this.extraNameInput
          this.selectedItemsExtras[extraSetIndex].price = this.extraPriceInput
          this.selectedItemsExtras[extraSetIndex].category = this.extraCategoryInput.toLowerCase()


          //These are the new items, that did not originally have the extra
          this.mutableFoodItems.filter(x => this.itemCheckboxSelection.filter(id => !this.originalExtra.items.includes(id)).includes(x.id)).forEach(food => {
            if (!food.queries) {
              Vue.set(food, 'queries', ['extras'])
            } else if (!food.queries.includes('extras')) {
              food.queries.push('extras')
            }
            if (food.extras) {
              food.extras.push(JSON.parse(JSON.stringify({
                name: this.extraNameInput,
                price: this.extraPriceInput,
                category: this.extraCategoryInput.toLowerCase()
              })))
            } else {
              Vue.set(food, 'extras', [JSON.parse(JSON.stringify({
                name: this.extraNameInput,
                price: this.extraPriceInput,
                category: this.extraCategoryInput.toLowerCase()
              }))])
            }
            this.selectedItemsExtras[extraSetIndex].items.push(food.id)
          })

          //These are the items, that did have it originally and still have it
          this.mutableFoodItems.filter(x => this.itemCheckboxSelection.filter(id => this.originalExtra.items.includes(id)).includes(x.id)).forEach(food => {
            let extraIndex = food.extras.findIndex(extra => extra.name == this.originalExtra.name && extra.price == this.originalExtra.price)
            Vue.set(food.extras, extraIndex, JSON.parse(JSON.stringify({
              name: this.extraNameInput,
              price: this.extraPriceInput,
              category: this.extraCategoryInput.toLowerCase()
            })))
          })

          //These are the items, that originally had it but don't anymore
          this.mutableFoodItems.filter(x => this.originalExtra.items.filter(id => !this.itemCheckboxSelection.includes(id)).includes(x.id)).forEach(food => {
            let extraSetFoodIndex = this.selectedItemsExtras[extraSetIndex].items.findIndex(id => id == food.id)
            this.selectedItemsExtras[extraSetIndex].items.splice(extraSetFoodIndex, 1)
            let extraIndex = food.extras.findIndex(extra => extra.name == this.originalExtra.name && extra.price == this.originalExtra.price)
            food.extras.splice(extraIndex, 1)
            if (food.extras.length == 0) {
              let queryIndex = food.queries.findIndex(x => x == 'extras')
              food.queries.splice(queryIndex, 1)
            }
          })
        } else {
          this.deleteSingleExtra({
            name: this.originalExtra.name,
            price: this.originalExtra.price,
            items: this.originalExtra.items
          })
        }
      } else {
        this.mutableFoodItems.filter(x => this.itemCheckboxSelection.includes(x.id)).forEach(x => {
          if (!x.queries) {
            x.queries = ['extras']
          } else if (!x.queries.includes('extras')) {
            x.queries.push('extras')
          }
          if (x.extras) {
            x.extras.push(JSON.parse(JSON.stringify({
              name: this.extraNameInput,
              price: this.extraPriceInput,
              category: this.extraCategoryInput.toLowerCase()
            })))
          } else {
            x.extras = [JSON.parse(JSON.stringify({
              name: this.extraNameInput,
              price: this.extraPriceInput,
              category: this.extraCategoryInput.toLowerCase()
            }))]
          }

        })
        this.selectedItemsExtras.push(JSON.parse(JSON.stringify({
          items: this.itemCheckboxSelection,
          name: this.extraNameInput,
          price: this.extraPriceInput,
          category: this.extraCategoryInput.toLowerCase(),
        })))


      }

      this.extraInputDialog = false
      localStorage.mutableFoodItems = JSON.stringify(this.mutableFoodItems);
      this.hasSavedChanges = true

    },
    handleExtraPaste() {
      this.copiedExtras.forEach(extra => {
        let idArray = JSON.parse(JSON.stringify(this.selected.map(x => x.id)))
        let existingSelectedExtra = this.selectedItemsExtras.find(x => x.name == extra.name && x.price == extra.price && x.category == extra.category)
        if (existingSelectedExtra) {
          existingSelectedExtra.items = idArray
        } else {
          this.selectedItemsExtras.push({
            ...extra,
            items: idArray
          })
        }
        this.mutableFoodItems.filter(x => idArray.includes(x.id)).forEach(food => {
          console.log(JSON.parse(JSON.stringify(food)))
          if (!food.queries) {
            Vue.set(food, 'queries', ['extras'])
          } else if (!food.queries.includes('extras')) {
            food.queries.push('extras')
          }
          if (food.extras && !food.extras.some(e => e.name == extra.name && e.price == extra.price && e.category == extra.category)) {
            food.extras.push(JSON.parse(JSON.stringify(extra)))
          } else {
            food.extras = [JSON.parse(JSON.stringify(extra))]
          }
          console.log(JSON.parse(JSON.stringify(food)))

        })
      })
      this.copiedExtras = []
      this.hasSavedChanges = true
    },
    handleQuestionSave() {
      if (this.originalQuestion.edit) {
        if (this.itemCheckboxSelection.length > 0) {
          //This edits the name/price of the set of the questions of all selected items
          //The .items prop is set dynamically a bit further down
          let questionSetIndex = this.findQuestionInArray(this.originalQuestion, this.selectedItemsQuestions)
          this.selectedItemsQuestions[questionSetIndex].question = this.questionNameInput
          this.selectedItemsQuestions[questionSetIndex].mandatory = this.questionMandatoryInput
          this.selectedItemsQuestions[questionSetIndex].choices = this.inputAnswers


          //These are the new items, that did not originally have the question
          this.mutableFoodItems.filter(x => this.itemCheckboxSelection.filter(id => !this.originalQuestion.items.includes(id)).includes(x.id)).forEach(food => {
            if (!food.queries) {
              Vue.set(food, 'queries', ['questions'])
            } else if (!food.queries.includes('questions')) {
              food.queries.push('questions')
            }
            if (food.questions) {
              food.questions.push(JSON.parse(JSON.stringify({
                question: this.questionNameInput,
                mandatory: this.questionMandatoryInput,
                choices: this.inputAnswers
              })))
            } else {
              Vue.set(food, 'questions', [JSON.parse(JSON.stringify({
                question: this.questionNameInput,
                mandatory: this.questionMandatoryInput,
                choices: this.inputAnswers
              }))])
            }
            this.selectedItemsQuestions[questionSetIndex].items.push(food.id)
          })

          //These are the items, that did have it originally and still have it
          this.mutableFoodItems.filter(x => this.itemCheckboxSelection.filter(id => this.originalQuestion.items.includes(id)).includes(x.id)).forEach(food => {
            let questionIndex = this.findQuestionInArray(this.originalQuestion, food.questions)
            Vue.set(food.questions, questionIndex, JSON.parse(JSON.stringify({
              question: this.questionNameInput,
              mandatory: this.questionMandatoryInput,
              choices: this.inputAnswers
            })))
          })

          //These are the items, that originally had it but don't anymore
          this.mutableFoodItems.filter(x => this.originalQuestion.items.filter(id => !this.itemCheckboxSelection.includes(id)).includes(x.id)).forEach(food => {
            let questionSetFoodIndex = this.selectedItemsQuestions[questionSetIndex].items.findIndex(id => id == food.id)
            this.selectedItemsQuestions[questionSetIndex].items.splice(questionSetFoodIndex, 1)
            let questionIndex = this.findQuestionInArray(this.originalQuestion, food.questions)
            food.questions.splice(questionIndex, 1)
            if (food.questions.length == 0) {
              let queryIndex = food.queries.findIndex(x => x == 'questions')
              food.queries.splice(queryIndex, 1)
            }
          })
        } else {
          this.deleteSingleQuestion(this.originalQuestion)
        }
      } else {
        let inputQuestion = JSON.parse(JSON.stringify({
          question: this.questionNameInput,
          mandatory: this.questionMandatoryInput,
          choices: this.inputAnswers
        }))
        if (inputQuestion.choices.some(x => x.extras && x.extras.length > 0) && !window.confirm('Sie speichern hiermit die komplette Frage, und nicht nur das Extra was Sie eintragen. Weiter?')) {
          return
        }
        if ((this.answerNameInput || this.answerPriceInput) && !window.confirm('Sie haben die Antwort: ' + this.answerNameInput + ' ' + (this.answerPriceInput / 100 + this.answerPriceInput != 0 ? '€' : '') + ' noch nicht zur Frage hinzugefügt. Weiter?')) {
          return
        }
        this.mutableFoodItems.filter(x => this.itemCheckboxSelection.includes(x.id)).forEach(x => {
          if (!x.queries) {
            x.queries = ['questions']
          } else if (!x.queries.includes('questions')) {
            x.queries.push('questions')
          }
          if (x.questions) {
            x.questions.push(inputQuestion)
          } else {
            x.questions = [inputQuestion]
          }
        })
        this.selectedItemsQuestions.push(JSON.parse(JSON.stringify({
          items: this.itemCheckboxSelection,
          question: this.questionNameInput,
          mandatory: this.questionMandatoryInput,
          choices: this.inputAnswers
        })))
      }

      this.questionInputDialog = false
      localStorage.mutableFoodItems = JSON.stringify(this.mutableFoodItems);
      this.hasSavedChanges = true
    },
    handleChosenItemsDescriptionSave() {
      this.mutableFoodItems.filter(x => this.selected.map(x => x.id).includes(x.id)).forEach(x => {
        x.description = this.selectedItemsDescriptionInput
      })
      this.selectedItemsDescriptionEditable = false
      this.selectedItemsDescriptionInput = undefined
      this.selectedItemsDescriptionString = [...new Set(this.selected.map(item => item.description))].filter(Boolean).join(', ')
      localStorage.mutableFoodItems = JSON.stringify(this.mutableFoodItems);
      this.hasSavedChanges = true
    },
    handleChosenItemsPriceSave() {
      this.mutableFoodItems.filter(x => this.selected.map(x => x.id).includes(x.id)).forEach(x => {
        x.price = this.selectedItemsPriceInput
      })
      this.selectedItemsPriceEditable = false
      this.selectedItemsPriceInput = undefined
      this.selectedItemsPricesString = [...new Set(JSON.parse(JSON.stringify(this.selected)).sort((a, b) => a.price - b.price).map(item => (item.price / 100 + ' €')))].join(', ')
      localStorage.mutableFoodItems = JSON.stringify(this.mutableFoodItems);
      this.hasSavedChanges = true
    },
    handleRequiredLabelSave() {
      this.mutableFoodItems.filter(x => this.itemCheckboxSelection.includes(x.id)).forEach(x => {
        x.requiredLabels = JSON.parse(JSON.stringify(this.requiredLabelsInput))
      })
      this.requiredLabelsinputDialog = false
      this.hasSavedChanges = true
    },
    //these handle deleting stuff
    deleteAnswer(answer) {
      let answerIndex = this.inputAnswers.findIndex(x => x.name == answer.name && x.price == answer.price)
      this.inputAnswers.splice(answerIndex, 1)
    },

    deleteSingleCategory(category) {
      this.mutableFoodItems.filter(item => item.category == category.id).forEach(item => {
        this.deleteSingleItem(item)
      })
      this.mutableFoodCategories.forEach(obj => {
        if (obj.index > category.index) {
          obj.index--
        }
      })
      let index = this.mutableFoodCategories.findIndex(obj => obj.id == category.id)
      localStorage.mutableFoodCategories = JSON.stringify(this.mutableFoodCategories);
      this.hasSavedChanges = true
      this.mutableFoodCategories.splice(index, 1)
    },
    deleteSingleItem(item) {
      this.mutableFoodItems.forEach(obj => {
        if (obj.index > item.index && obj.category == item.category) {
          obj.index--
        }
      })
      let index = this.mutableFoodItems.findIndex(food => food.id == item.id)
      this.mutableFoodItems.splice(index, 1)
      localStorage.mutableFoodItems = JSON.stringify(this.mutableFoodItems);
      this.hasSavedChanges = true
    },
    deleteSingleExtra(extra) {
      let extraSetIndex = this.selectedItemsExtras.findIndex(x => x.name == extra.name && x.price == extra.price)
      this.selectedItemsExtras.splice(extraSetIndex, 1)
      extra.items.forEach(itemId => {
        let food = this.mutableFoodItems.find(x => x.id == itemId)
        let extraIndex = food.extras.findIndex(x => x.name == extra.name && x.price == extra.price)
        food.extras.splice(extraIndex, 1)
        if (food.extras.length == 0) {
          let queryIndex = food.queries.findIndex(x => x == 'extras')
          food.queries.splice(queryIndex, 1)
        }
      })
      localStorage.mutableFoodItems = JSON.stringify(this.mutableFoodItems);
      this.hasSavedChanges = true
    },
    deleteSingleQuestion(question) {
      let questionSetIndex = this.findQuestionInArray(question, this.selectedItemsQuestions)
      this.selectedItemsQuestions.splice(questionSetIndex, 1)
      question.items.forEach(itemId => {
        let food = this.mutableFoodItems.find(x => x.id == itemId)
        let questionIndex = food.questions.findIndex(x => x.question == question.question && x.mandatory == question.mandatory && x.choices.length == question.choices.length)
        food.questions.splice(questionIndex, 1)
        if (food.questions.length == 0) {
          let queryIndex = food.queries.findIndex(x => x == 'questions')
          food.queries.splice(queryIndex, 1)
        }
      })
      localStorage.mutableFoodItems = JSON.stringify(this.mutableFoodItems);
      this.hasSavedChanges = true
    },
    //these commit/discard all working changes to the database and menu stored in vuex
    discardAllChanges() {
      if (confirm("Es werden alle ungespeicherten Änderungen verworfen. Sind Sie sicher?")) {
        localStorage.removeItem('mutableFoodCategories')
        localStorage.removeItem('mutableFoodItems')
        this.mutableFoodCategories = JSON.parse(JSON.stringify(this.foodCategories))
        this.mutableFoodItems = JSON.parse(JSON.stringify(this.foodItems))
        this.selectedFoodItems = {}
        this.hasSavedChanges = false
      }
    },
    submitAllChanges() {
      //This compares the mutable arrays with their original counterparts, to find out any changes
      //and commit those to the store and the firebase database
      this.loading = true
      //These will be compared to the current menu
      let originalFoodItems = JSON.parse(JSON.stringify(this.foodItems))
      let originalFoodCategories = JSON.parse(JSON.stringify(this.foodCategories))
      //These will be used to write to the database
      let changedFoodCategories = []
      let addedFoodCategories = []
      let removedFoodCategories = []
      let changedFoodItems = []
      let addedFoodItems = []
      let removedFoodItems = []
      let extraRewrites = []
      let questionRewrites = []

      //This gets the added and changed objects and puts them in their respective arrays,
      this.mutableFoodCategories.forEach(category => {
        let oldCategoryIndex = originalFoodCategories.findIndex(x => x.id == category.id)
        let oldCategory = originalFoodCategories[oldCategoryIndex] || undefined
        if (oldCategory) {
          if (oldCategory.name != category.name || oldCategory.index != category.index || oldCategory.description != category.description || oldCategory.image != category.image) {
            changedFoodCategories.push({
              id: category.id,
              name: category.name,
              description: category.description || '',
              image: category.image || '',
              index: category.index
            })
          }
          originalFoodCategories.splice(oldCategoryIndex, 1)
        } else {
          addedFoodCategories.push({
            id: category.id,
            name: category.name,
            description: category.description || '',
            image: category.image || '',
            index: category.index
          })
        }
        // This makes sure the indexes are never broken, which sometimes occasionally happened for no good reason! :(
        this.mutableFoodItems.filter(x => x.category == category.id).forEach((obj, index) => {
          obj.index = index + 1
        })
      })
      //The only objects left in the original Array are the ones that are not in the new menu anymore,
      //so the ones that were removed from the menu.
      removedFoodCategories = removedFoodCategories.concat(originalFoodCategories)

      //This gets the added and changed objects and puts them in their respective arrays,
      this.mutableFoodItems.forEach(item => {
        let oldItemIndex = originalFoodItems.findIndex(x => x.id == item.id)
        let oldItem = originalFoodItems[oldItemIndex] || undefined

        if (oldItem) {
          if (item.extras) {
            if (oldItem.extras) {
              // This checks if any new or changed extras exist. A full rewrite is done if there are any
              // changes to the extras
              let anyAddedOrChanged = item.extras.some(extra => {
                let oldExtraIndex = oldItem.extras.findIndex(x => x.name == extra.name && x.price == extra.price && x.category == extra.category)
                let oldExtra = oldItem.extras[oldExtraIndex] || undefined
                if (oldExtra) {
                  oldItem.extras.splice(oldExtraIndex, 1)
                  return false
                } else {
                  return true
                }
              })
              //in oldItem.extras the only ones that are still around are the ones that are not in the new menu
              //so the ones that were deleted!
              if (oldItem.extras.length > 0 || anyAddedOrChanged) {
                extraRewrites.push({
                  extras: item.extras,
                  item: item.id,
                })
              }
            } else {
              extraRewrites.push({
                extras: item.extras,
                item: item.id
              })
            }

          }
          if (item.questions) {
            if (oldItem.questions) {
              // This checks if any new or changed question exist. A full rewrite is done if there are any
              // changes to the questions
              let anyAddedOrChanged = item.questions.some(question => {
                let oldQuestionIndex = this.findQuestionInArray(question, oldItem.questions)
                let oldQuestion = oldItem.questions[oldQuestionIndex] || undefined
                if (oldQuestion) {
                  return false
                } else {
                  return true
                }
              })
              //in oldItem.questions the only ones that are still around are the ones that are not in the new menu
              //so the ones that were deleted!
              if (oldItem.questions.length > 0 || anyAddedOrChanged) {
                questionRewrites.push({
                  questions: item.questions,
                  item: item.id
                })
              }
            } else {
              questionRewrites.push({
                questions: item.questions,
                item: item.id
              })
            }

          }
          if (oldItem.category != item.category || oldItem.name != item.name || oldItem.description != item.description || oldItem.price != item.price
              || oldItem.index != item.index || oldItem.number != item.number || !Object.prototype.hasOwnProperty.call(oldItem, "queries")
              || oldItem.veg != item.veg
              || (oldItem.queries.includes('extras') != item.queries.includes('extras'))
              || (oldItem.queries.includes('questions') != item.queries.includes('questions'))
              || (!Object.prototype.hasOwnProperty.call(oldItem, "requiredLabels"))
              || !(oldItem.requiredLabels.length === item.requiredLabels.length && oldItem.requiredLabels.sort().every(function (value, index) {
                return value === item.requiredLabels.sort()[index]
              }))
          ) {
            changedFoodItems.push({
              id: item.id,
              category: item.category,
              name: item.name,
              description: item.description || '',
              price: Number(item.price),
              number: item.number || '',
              index: item.index,
              veg: item.veg || false,
              queries: item.queries || [],
              requiredLabels: item.requiredLabels || [],
            })
          }
          originalFoodItems.splice(oldItemIndex, 1)
        } else {
          if (item.extras) {
            extraRewrites.push({
              extras: item.extras,
              item: item.id
            })
          }
          if (item.questions) {
            questionRewrites.push({
              questions: item.questions,
              item: item.id
            })
          }
          addedFoodItems.push({
            id: item.id,
            category: item.category,
            name: item.name,
            description: item.description || '',
            price: Number(item.price),
            number: item.number || '',
            index: item.index,
            queries: item.queries || [],
            requiredLabels: item.requiredLabels || []
          })
        }
      })
      //The only objects left in the original Array are the ones that are not in the new menu anymore,
      //so the ones that were removed from the menu.
      removedFoodItems = removedFoodItems.concat(originalFoodItems)

      let foodPromises = JSON.parse(JSON.stringify(addedFoodCategories)).map(x => this.addFoodCategory(x))

      //This Promise contains all things that rely on all foodCategories being saved correctly already
      let bigPromise = Promise.all(foodPromises).then((values) => {
        return new Promise((resolve, reject) => {
          let addedFoodPromise = addedFoodItems.map(item => {
            let categoryIndex = addedFoodCategories.findIndex(category => category.id == item.category)
            //This is done so if the references Item was newly added the id is the final one and not the temporary one
            if (categoryIndex != -1) {
              item.category = values[categoryIndex].id
            }
            return this.addFoodItem(item)
          })
          let changedFoodPromise = changedFoodItems.map(item => {
            let categoryIndex = addedFoodCategories.findIndex(category => category.id == item.category)
            //This is done so if the references Item was newly added the id is the final one and not the temporary one
            if (categoryIndex != -1) {
              item.category = values[categoryIndex].id
            }
            return this.editFoodItem(item)
          })
          //This Promise contains all things that rely on all foodItems being saved correctly already
          Promise.all([Promise.all(addedFoodPromise), Promise.all(changedFoodPromise)]).then((foodValues) => {
            let extraRewritePromises = extraRewrites.map(rewrite => {
              let foodIndex = addedFoodItems.findIndex(item => item.id == rewrite.item)
              //This is done so if the references Item was newly added the id is the final one and not the temporary one
              if (foodIndex != -1) {
                rewrite.item = foodValues[0][foodIndex].id
              }
              return this.setExtras(rewrite)
            })
            let questionRewritePromises = questionRewrites.map(rewrite => {
              let foodIndex = addedFoodItems.findIndex(item => item.id == rewrite.item)
              //This is done so if the references Item was newly added the id is the final one and not the temporary one
              if (foodIndex != -1) {
                rewrite.item = foodValues[0][foodIndex].id
              }
              return this.setQuestions(rewrite)
            })
            Promise.all([Promise.all(extraRewritePromises), Promise.all(questionRewritePromises)]).then(() => {
              resolve()
            })
          }).catch(err => reject(err))
        })
      })
      let changedFoodCategoriesPromise = Promise.all(changedFoodCategories.map(category => this.editFoodCategory(category)))
      let deletedItemsPromise = Promise.all(removedFoodItems.map(obj => this.deleteFoodItem(obj)))
      let deletedCategoriesPromise = Promise.all(removedFoodCategories.map(obj => this.deleteFoodCategory(obj.id)))
      Promise.all([bigPromise, changedFoodCategoriesPromise, deletedItemsPromise, deletedCategoriesPromise]).then(() => {
        localStorage.removeItem('mutableFoodCategories')
        localStorage.removeItem('mutableFoodItems')
        this.mutableFoodCategories = JSON.parse(JSON.stringify(this.foodCategories))
        this.mutableFoodItems = JSON.parse(JSON.stringify(this.foodItems))
        this.hasSavedChanges = false
        this.setLastUpdated()
        this.loading = false
        this.selectedFoodItems = {}
      }).catch(err => console.log(err))
    },
    //misc helper stuff
    itemStringFromIds(items) {
      return [...new Set(this.selected.filter(x => items.includes(x.id)).map(item => item.name))].join(', ')
    },
    findQuestionInArray(question, array) {
      return array.findIndex(x => {
        if (x.question == question.question && x.mandatory == question.mandatory && x.choices.length == question.choices.length) {
          return x.choices.every(xChoice => {
            return question.choices.some(choice => {
              if (choice.name == xChoice.name && choice.price == xChoice.price) {
                if (choice.extras || xChoice.extras) {
                  if (choice.extras && xChoice.extras && (choice.extras.length == xChoice.extras.length)) {
                    return choice.extras.every(extra => xChoice.extras.some(xExtra => extra.name == xExtra.name && extra.price == xExtra.price && xExtra.category == extra.category))
                  } else {
                    return false
                  }
                } else {
                  return true
                }
              } else {
                return false
              }
            })
          })
        } else {
          return false
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      foodCategories: 'menuStore/foodCategories',
      foodItems: 'menuStore/foodItems',
      hasCheckedInfo: 'restaurantStore/hasCheckedInfo',
      menuLoaded: 'menuStore/menuLoaded'
    }),
    //the input isn't flat by default for performance reasons
    selected() {
      return Object.values(this.selectedFoodItems).flat()
    }
  },
  created() {
    if (this.hasCheckedInfo && !this.menuLoaded) {
      store.dispatch('menuStore/fetchFoodCategories').then(() => {
        this.getMutableItems()
        this.loading = false
      });
    } else if (this.hasCheckedInfo) {
      this.getMutableItems()
      this.loading = false
    }
  },
  watch: {
    hasCheckedInfo() {
      if (!this.menuLoaded) {
        store.dispatch('menuStore/fetchFoodCategories').then(() => {
          this.getMutableItems()
          this.loading = false
        });
      } else {
        this.getMutableItems()
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="sass">
.hoverBackground
  cursor: pointer

  &:hover
    background: #e0feff

.greyBackground
  background: #f2f2f2

</style>
